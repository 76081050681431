import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Title, Text } from "../../components/Core";

const Timeline = () => {
  return (
    <>
      <VerticalTimeline layout="1-column-left">
        <VerticalTimelineElement
          iconStyle={{ background: "#ffffff", color: "#fff" }}
        >
          <Title variant="card" fontSize="24px" fontWeight="600">
            Q1 2022
          </Title>
          <Text>Announce 2022 roadmap and seek community feedback</Text>
          <Text>Publish Alkemi Earn protocol v2 white paper</Text>
          <Text>
            Research and prioritize token utility expansion prospects; propose
            to the community
          </Text>
          <Text>
            Publish ‘DeFi Standards’ report and initiate POCs/pilots with a seed
            group
          </Text>
          <Text>
            Share governance process with the community and invite for proposals
          </Text>
          <Text>Launch V2 of Alkemi Earn UI</Text>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: "#ffffff", color: "#fff" }}
        >
          <Title variant="card" fontSize="24px" fontWeight="600">
            Q2 2022
          </Title>
          <Text>Alkemi Earn protocol v2 release on Testnet</Text>
          <Text>Earn v2 smart contract audit</Text>
          <Text>
            Propose token utility expansion options to community governance
          </Text>
          <Text>‘DeFi Standards’ working group announcement and roadmap</Text>
          <Text>
            Improve protocol governance process with learnings from Q1
          </Text>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: "#ffffff", color: "#fff" }}
        >
          <Title variant="card" fontSize="24px" fontWeight="600">
            Q3 2022
          </Title>
          <Text>Alkemi Earn protocol v2 release on Mainnet</Text>
          <Text>Earn v2 bug bounty programs</Text>
          <Text>
            Implement and release a new token utility program (pending community
            votes)
          </Text>
          <Text>
            Publicize ‘DeFi Standards’ to expand participation from firms and
            regulators
          </Text>
          <Text>
            New governance portal unifying process and participation stats
          </Text>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: "#ffffff", color: "#fff" }}
        >
          <Title variant="card" fontSize="24px" fontWeight="600">
            Q4 2022
          </Title>
          <Text>Earn v2 improvement proposals</Text>
          <Text>
            Protocol majority decentralized, governance multi-sig process
            deprecated
          </Text>
          <Text>
            Automate ‘DeFi Standards’ participation with hub website and
            self-service tools
          </Text>
          <Text>End-to-end on-chain governance pilot on Testnet</Text>
          <Text>Roadmap for 2023 released for community feedback</Text>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </>
  );
};

export default Timeline;
