import React from "react";
import PageTitle from "../sections/common/PageTitle";
import PageWrapper from "../components/PageWrapper";
import { Box } from "../components/Core";
import Summary from "../sections/roadmap/Summary";
import Timeline from "../sections/roadmap/Timeline";
import { Helmet } from "react-helmet";

const Roadmap = () => {
  return (
    <>
      <Helmet>
        <title>Roadmap - Briding CeFi to DeFi | Alkemi Network</title>
        <meta
          name="description"
          content="Alkemi Network's roadmap to building a compliant DeFi network for everyone"
        />
        <meta property="og:title" content="Roadmap | Alkemi Network" />
        <meta
          property="og:description"
          content="Alkemi Network's roadmap to building a compliant DeFi network for everyone"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content="https://alkemi.network/roadmap/" />
        <link rel="canonical" href="https://alkemi.network/roadmap/" />
      </Helmet>
      <PageWrapper footerDark>
        <PageTitle title="The Roadmap">
          Alkemi Network's goals and milestones of bringing compliant and secure
          DeFi to everyone.
        </PageTitle>
        <Box>
          <Summary />
          <Timeline />
        </Box>
      </PageWrapper>
    </>
  );
};
export default Roadmap;
