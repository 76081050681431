import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Box } from "../../components/Core";

const CaseDetails = () => (
  <>
    <Section className="position-relative" borderBottom="1px solid #eae9f2;">
      <Container>
        <Row>
          <Col lg="12" xl="10">
            <Box pb={["30px", null, "45px"]}>
              <p>
                The Alkemi Network core team is excited to announce its 2022
                roadmap plans for the protocol, which includes the following 3
                themes:
              </p>
            </Box>
            <Box pb={["30px", null, "35px"]}>
              <h4 className="block-title mb-2">
                1. Increase capital efficiency:
              </h4>
              <p>
                Work toward v2 release with a focus on improving borrower and
                lender experience. We will be exploring new protocol features
                such as better borrower-lender matching through Request for
                Quotes ‘RFQ’, reducing the cost of capital through
                undercollateralized loans, increasing token utility through
                staking programs, and addressing institutional user needs
                through more specialized UI/UX and advanced reporting. We are
                keenly aware that these features require in-depth research and
                feedback cycles with the ecosystem prior to mainnet release.
              </p>
            </Box>
            <Box pb={["30px", null, "35px"]}>
              <h4 className="block-title mb-2">
                2. Maximize compliant liquidity:{" "}
              </h4>
              <p>
                Collaborate with major institutional hubs such as custodians,
                exchanges, and wallet providers to initiate DeFi standards for
                institutional participation, involving decentralized identity,
                KYC/AML interoperability, and shared compliance policy
                frameworks. These standards will reinforce trust, credibility,
                and efficiency for more institutions to participate in DeFi in a
                compliant way, and will accelerate the flow of big capital into
                our ‘Verified’ Pool.
              </p>
            </Box>
            <Box>
              <h4 className="block-title mb-2">
                3. Progressive decentralization:{" "}
              </h4>
              <p>
                Double down on community development and increase the
                sophistication of tooling for protocol governance engagement. As
                the network grows, our plan is to guide the community to take
                ownership of protocol developments and decisions, with an
                ultimate goal to hand the keys to the protocol governance
                mechanism.
              </p>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CaseDetails;
